<template>
    <router-view></router-view>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { version } from '../package.json'
import { useI18n } from 'vue-i18n';

console.log(version);

//i18n locale 유지
const store = useStore();
const i18n = useI18n();
const getLocale = computed(() => store.getters.getLocale);

if( getLocale.value != null ){
  i18n.locale.value = getLocale.value;
}else {
  i18n.locale.value = "Korea";
  store.commit("setLocale", "Korea");
}

</script>

<style>
#app {
  height: 100%;
}

/* scroll disable */
.scrollDisable {overflow:hidden !important; touch-action:none;}

/* ion loading */
.sc-ion-loading-md-h {
  --ion-backdrop-opacity: 0.2;
}

.loading-wrapper {
  background: transparent !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  color: #fff;
}

/* input error */
/* error */
.input_error_text {
  color: var(--alert-alert-60, #DA1E28) !important;
  /* B3 */
  font-family: Pretendard !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 142.857% */

  margin-top: 11px;
}
.mp--infoData .input_error_text {
  margin-top: 0 !important;
  font-size: 12px !important;
}

.input_error {
  border-radius: 5px;
  border: 1px solid var(--alert-alert-60, #DA1E28) !important;
  -webkit-animation: horizontal-shaking 0.35s 1;
  animation: horizontal-shaking 0.35s 1;
}
@keyframes horizontal-shaking {
 0% { transform: translateX(0) }
 25% { transform: translateX(5px) }
 50% { transform: translateX(-5px) }
 75% { transform: translateX(5px) }
 100% { transform: translateX(0) }
}

</style>
